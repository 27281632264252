export enum ZIndex {
  Child = 1,
  PullDown,
  Balloon,
  ActionBar,
  TableHead,
  Filter,
  PageHead,
  SideBar,
  Header,
  Modal,
  Feedback,
  TopMost,
}
