import { css } from "@emotion/react";
import { text } from "@estie-inc/design-tokens";
import NextLink from "next/link";
import React, { MouseEventHandler } from "react";
import { Color, RadiusSize, LineHeight, Spacing } from "../../../../tokens";
import Tooltip from "../../../uiParts/dataDisplay/Tooltip";

export type MenuListProps = {
  menuItems: MenuItem[];
  width?: string;
  className?: string;
};

export type MenuItem = {
  label: string;
  subLabel?: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  href?: string;
  color?: React.CSSProperties["color"];
  isDisabled?: boolean;
  tooltipLabel?: string;
  tooltipPosition?: "Left" | "Right";
};

const MenuList: React.FC<MenuListProps> = ({ menuItems, width, className }) => {
  return (
    <ul css={menuList(width)} className={className} data-testid="menu_list">
      {menuItems.map((item: MenuItem, index) => {
        return (
          <li key={index}>
            {item.onClick && (
              <a
                data-testid={`menu_list_item_${index}`}
                css={styledItem(item.color, item.isDisabled)}
                onClick={item.onClick}
              >
                <p>{item.label}</p>
                {item.subLabel && <p css={subLabel}>{item.subLabel}</p>}
                {item.tooltipLabel && (
                  <Tooltip
                    css={tooltipStyle(item.tooltipPosition)}
                    className="uiParts-navigation-manuList__tooltip"
                  >
                    {item.tooltipLabel}
                  </Tooltip>
                )}
              </a>
            )}
            {item.href && (
              <NextLink href={item.href} passHref>
                <div css={styledItem(item.color, item.isDisabled)}>
                  <p>{item.label}</p>
                  {item.subLabel && <p css={subLabel}>{item.subLabel}</p>}
                  {item.tooltipLabel && (
                    <Tooltip
                      css={tooltipStyle(item.tooltipPosition)}
                      className="uiParts-navigation-manuList__tooltip"
                    >
                      {item.tooltipLabel}
                    </Tooltip>
                  )}
                </div>
              </NextLink>
            )}
          </li>
        );
      })}
    </ul>
  );
};

const menuList = (width?: string) => css`
  width: ${width ? width : "auto"};
  background: ${Color.White};
  border-radius: ${RadiusSize[2]};
  border: solid 1px ${Color.Neutral.Light.Primary};
  > li:not(:first-of-type) {
    border-top: solid 1px ${Color.Neutral.Light.Primary};
  }
`;

const styledItem = (
  color?: React.CSSProperties["color"],
  isDisabled?: boolean,
) => css`
  font-size: ${text.size[12]};
  line-height: ${LineHeight[100]};
  display: block;
  color: ${color ? color : Color.Neutral.Base.Primary};
  padding: ${Spacing[8]};
  cursor: pointer;
  position: relative;
  &:hover {
    text-decoration: underline;
    .uiParts-navigation-manuList__tooltip {
      display: inline-block;
    }
  }
  ${isDisabled &&
  css`
    color: ${Color.Neutral.Base.Secondary};
    cursor: default;
    &:hover {
      text-decoration: none;
    }
    &:active {
      pointer-events: none;
      // aタグからbuttonタグに変更しonClickの無効化をdisabled属性で行うようにする
    }
  `}
`;

const subLabel = css`
  font-size: ${text.size[12]};
  color: ${Color.Neutral.Base.Secondary};
  margin-top: ${Spacing[4]};
`;

const tooltipStyle = (tooltipPosition?: "Left" | "Right") => css`
  position: absolute;
  top: 0;
  display: none;
  width: max-content;
  ${tooltipPosition === "Right"
    ? css`
        left: calc(100% + 4px);
      `
    : css`
        right: calc(100% + 4px);
      `};
`;

export default MenuList;
