import React, {
  useState,
  createContext,
  useContext,
  ReactNode,
  useCallback,
} from "react";
import ClientOnlyPortal from "../../components/helpers/ClientOnlyPortal";
import Dialog from "../../components/uiParts/feedback/Dialog";

type DialogParams = {
  heading: string;
  description?: string;
  submitLabel: string;
  cancelLabel?: string;
  maxWidth?: React.CSSProperties["maxWidth"];
  onSubmit?: () => void;
  onCancel?: () => void;
};

type DialogCtx = {
  open: () => void;
  close: () => void;
  isOpen: () => boolean;
  setParams: (params: Partial<DialogParams>) => void;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type CtxFnWithArg = (...args: any) => any;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type CtxFn = () => any;
const defaultCtxFn = function <T extends CtxFnWithArg | CtxFn>(
  message: string,
  withArgs?: boolean,
) {
  if (withArgs) {
    return (...args: Parameters<T>): ReturnType<T> => {
      throw new Error(`${message}, args: ${args}`);
    };
  } else {
    return (): ReturnType<T> => {
      throw new Error(`${message}`);
    };
  }
};
const emt = (key: string) => `dialog context: function ${key} must be set`;
// dialogを開く挙動とstateの設定を行う関数をcontextで流してるけどサイズデカくなりすぎな気がする
const DialogContext = createContext<DialogCtx>({
  open: defaultCtxFn<DialogCtx["open"]>(emt("open")),
  close: defaultCtxFn<DialogCtx["close"]>(emt("close")),
  isOpen: defaultCtxFn<DialogCtx["isOpen"]>(emt("isOpen")),
  setParams: defaultCtxFn<DialogCtx["setParams"]>(emt("setParams"), true),
});
DialogContext.displayName = "DialogContext";

export const DIALOG_ID = "portal-dialog";
export const useDialog = () => {
  return useContext(DialogContext);
};

export const DialogProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [op, setIsOpen] = useState<boolean>(false);
  const [
    {
      heading,
      description,
      submitLabel,
      cancelLabel,
      maxWidth,
      onSubmit: onSubmitCallback,
      onCancel: onCancelCallback,
    },
    setParams,
  ] = useState<DialogParams>({
    heading: "dialog",
    submitLabel: "submit",
  });

  const onSubmit = useCallback(() => {
    if (onSubmitCallback) onSubmitCallback();
    setIsOpen(false);
  }, [onSubmitCallback]);
  const onCancel = useCallback(() => {
    if (onCancelCallback) onCancelCallback();
    setIsOpen(false);
  }, [onCancelCallback]);

  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);
  const isOpen = useCallback(() => op, [op]);
  const setParamsCb = useCallback(
    (params: Partial<DialogParams>) =>
      setParams((prev) => ({ ...prev, ...params })),
    [],
  );

  return (
    <DialogContext.Provider
      value={{ open, close, isOpen, setParams: setParamsCb }}
    >
      {children}
      <ClientOnlyPortal selector={`#${DIALOG_ID}`}>
        <Dialog
          isOpen={op}
          heading={heading}
          description={description}
          submitLabel={submitLabel}
          cancelLabel={cancelLabel}
          maxWidth={maxWidth}
          submitOnClick={onSubmit}
          cancelOnClick={onCancel}
        />
      </ClientOnlyPortal>
    </DialogContext.Provider>
  );
};
