import { css } from "@emotion/react";
import React, {
  useState,
  createContext,
  useContext,
  ReactNode,
  useRef,
  useCallback,
  useEffect,
} from "react";
import ClientOnlyPortal from "../../components/helpers/ClientOnlyPortal";
import Toast, { BackgroundType } from "../../components/uiParts/feedback/Toast";
import { ZIndex } from "../../tokens";
import { Spacing } from "../../tokens/spacing";

type ToastProps = {
  id: string;
  text: string;
  type?: keyof typeof BackgroundType;
};
type ToastInit = {
  text: string;
  type?: keyof typeof BackgroundType;
  limit?: number;
};

// toastを開く挙動をcontextで流す
const ToastContext = createContext({
  // FIXME: {} で受ける必要が無いなら、引数を削除して eslint-disable も消せるはず
  // eslint-disable-next-line no-empty-pattern
  showToast: ({}: ToastInit): string => {
    throw new Error("toast context: showToast must be set");
  },
  clearToast: (id: string): void => {
    throw new Error(`toast context: clearToast must be set, id: ${id}`);
  },
  clearAllToast: (): void => {
    throw new Error("toast context: clearAllToast must be set");
  },
});
ToastContext.displayName = "ToastContext";

export const TOAST_ID = "portal-toast";
export const useToast = () => {
  return useContext(ToastContext);
};

const ToastList: React.FC<{ toasts: ToastProps[] }> = ({ toasts }) => (
  <div
    css={css`
      position: fixed;
      top: ${Spacing[24]};
      right: ${Spacing[24]};
      left: ${Spacing[24]};
      z-index: ${ZIndex.Feedback};
      width: calc(100vw - 24px * 2);
    `}
  >
    {toasts.map((e, i) => (
      <Toast key={e.text + i} type={e.type || "Success"} isOpen={true}>
        {e.text}
      </Toast>
    ))}
  </div>
);

export const ToastProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const removeQueue = useRef<Map<string, NodeJS.Timeout>>(new Map());
  const [toasts, setToasts] = useState<ToastProps[]>([]);

  /**
   * useEffectでmount時にtoastを表示する場合、複数回実行される可能性があるため必ずcleanupでclearすること。
   */
  const showToast = useCallback(
    ({ text, type, limit = 3000 }: ToastInit): string => {
      // FIXME: もっといいランダムID生成あったら変える
      const id =
        new Date().getTime().toString(16) +
        Math.floor(100 * Math.random()).toString(16);
      setToasts((prev) => [...prev, { text, type, id }]);
      const timeout = setTimeout(() => {
        setToasts((prev) => prev.filter((toast) => toast.id !== id));
        removeQueue.current.delete(id);
      }, limit);
      removeQueue.current.set(id, timeout);
      return id;
    },
    [],
  );

  const clearToast = useCallback((id: string) => {
    const timer = removeQueue.current.get(id);
    if (!timer) return;
    clearTimeout(timer);
    removeQueue.current.delete(id);
    setToasts((prev) => prev.filter((e) => e.id !== id));
  }, []);

  const clearAllToast = useCallback(() => {
    Array.from(removeQueue.current.values()).forEach((e) => clearTimeout(e));
    removeQueue.current = new Map();
    setToasts([]);
  }, []);

  useEffect(() => {
    return () =>
      Array.from(removeQueue.current.values()).forEach((e) => clearTimeout(e));
  }, []);

  return (
    <ToastContext.Provider value={{ showToast, clearToast, clearAllToast }}>
      {children}
      <ClientOnlyPortal selector={`#${TOAST_ID}`}>
        <ToastList toasts={toasts} />
      </ClientOnlyPortal>
    </ToastContext.Provider>
  );
};
