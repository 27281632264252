import { UserProvider } from "@auth0/nextjs-auth0/client";
import "@estie-inc/estie-ui/style.css";
import "../styles/global.css";
import { components } from "@estie-inc/estie-ui";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { MantineProvider } from "@mantine/core";
import type { AppPropsWithLayout } from "next/app";
import { useMemo } from "react";
import { Provider as URQLProvider } from "urql";
import { z } from "zod";
import GoogleTagManager, {
  GoogleTagManagerId,
} from "../components/script/GoogleTagManager";
import { ModalProvider } from "../contexts/Modal/context";
import { urqlClient } from "../graphql";
import { DialogProvider } from "../hooks/context/dialog";
import { LoadingProvider } from "../hooks/context/loading";
import { PageLogWrapper } from "../hooks/context/pageLog";
import { ToastProvider } from "../hooks/context/toast";
import { Reset as ResetStyle } from "../styles/Reset";
import { googleTagManagerId } from "../utils/gtm";
import { zodCustomErrorMap } from "../utils/zodCustomErrorMap";

library.add(fas, fab);

z.setErrorMap(zodCustomErrorMap);

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  // @see: https://nextjs.org/docs/basic-features/layouts#per-page-layouts
  const getLayout = useMemo(
    () => Component.getLayout ?? ((page: React.ReactNode) => page),
    [Component],
  );

  const theme = {
    components,
    scale: 1.0,
  };

  return (
    <>
      <GoogleTagManager
        googleTagManagerId={googleTagManagerId as GoogleTagManagerId}
      />
      <ResetStyle />
      <URQLProvider value={urqlClient}>
        <UserProvider>
          <MantineProvider theme={theme}>
            <ModalProvider>
              <DialogProvider>
                <ToastProvider>
                  <LoadingProvider>
                    <PageLogWrapper>
                      {getLayout(<Component {...pageProps} />)}
                    </PageLogWrapper>
                  </LoadingProvider>
                </ToastProvider>
              </DialogProvider>
            </ModalProvider>
          </MantineProvider>
        </UserProvider>
      </URQLProvider>
    </>
  );
}

export default MyApp;
