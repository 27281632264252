import { Global, css } from "@emotion/react";
import React from "react";

export const Reset: React.FC = () => <Global styles={reset} />;

const reset = css`
  /* FIXME: estie-uiのglobal.cssでoutlineが設定されているが、リースのフォームの作り的に崩れた表示になってしまうので打ち消している。今後、input,textarea, selectboxをestie-ui配信のものに変更し、この指定を削除したい。なので、このファイルに追記はせず、削除する方向性に持っていきたい。 */
  :focus:not(a, button, input[type="radio"], input[type="checkbox"]) {
    outline: none;
  }
  :focus-visible {
    /* color.primary.light (colorPalette.1stBlue[400]) */
    outline: none;
  }
`;
