export const Color = {
  Black: "#222222",
  White: "#FFFFFF",
  Primary: {
    Base: "#194FFF",
    Light: "#C8D7FF",
    Pale: "#E8EFFF",
  },
  Neutral: {
    Base: {
      Primary: "#1B1F23",
      Secondary: "#72777D",
    },
    Light: {
      Primary: "#D9DEE3",
      Secondary: "#E6ECF1",
    },
    Pale: {
      Primary: "#F0F5FA",
      Secondary: "#F8FAFC",
    },
  },
  Attention: {
    Caution: {
      Base: "#E73C4A",
      Light: "#FBB2BB",
      Pale: "#FFEBEE",
    },
    Success: {
      Base: "#00A35F",
      Light: "#8DE7B7",
      Pale: "#E3FCEE",
    },
  },
  Brand: {
    Excel: "#2F6A3D",
    Twitter: "#1D99ED",
    Pdf: "#B32E2B",
  },
  Data: {
    Blue: {
      Primary: "#009FEF",
      Secondary: "#B3E7FF",
    },
    Violet: {
      Primary: "#9D4CFA",
      Secondary: "#EED3FF",
    },
    Red: {
      Primary: "#E73C4A",
      Secondary: "#FDCED5",
    },
    Pink: {
      Primary: "#D62F8D",
      Secondary: "#FCC9E6",
    },
    Orange: {
      Primary: "#FF870F",
      Secondary: "#FFE3BF",
    },
    Mellow: {
      Primary: "#F2B50D",
      Secondary: "#FFF4BC",
    },
    Lime: {
      Primary: "#7AC812",
      Secondary: "#E3FBBB",
    },
    Green: {
      Primary: "#00A35F",
      Secondary: "#B6F2D1",
    },
    Cyan: {
      Primary: "#00A2BF",
      Secondary: "#A9F3FB",
    },
    Gray: {
      Primary: "#6D7D9B",
      Secondary: "#C4CBDB",
    },
  },
  Opacity: {
    0: "#1B1F2300",
    20: "#1B1F2320",
    40: "#1B1F2340",
    60: "#1B1F2360",
    80: "#1B1F2380",
    100: "#1B1F23",
  },
} as const;
