import { useRouter } from "next/router";
import React, { ReactNode } from "react";
import { useQuery } from "urql";
import { LogPageAccessDocument } from "../../graphql";

export const PageLogWrapper: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const router = useRouter();
  useQuery({
    query: LogPageAccessDocument,
    variables: {
      pathname: router.pathname,
      query: JSON.stringify(router.query),
      asPath: router.asPath,
    },
  });
  return children;
};
