const BpType = {
  Xs: 320,
  Sm: 480,
  Md: 960,
  Lg: 1280,
  Xl: 1440,
};
export const Up = (bp: keyof typeof BpType) =>
  `@media (min-width: ${BpType[bp]}px)`;
export const Down = (bp: keyof typeof BpType) =>
  `@media (max-width: ${BpType[bp]}px)`;
