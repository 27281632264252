import { css, keyframes } from "@emotion/react";
import { text } from "@estie-inc/design-tokens";
import React, {
  MouseEvent,
  MouseEventHandler,
  useCallback,
  useState,
} from "react";
import { Color, Spacing, RadiusSize, LineHeight } from "../../../../tokens";
import Overlay from "../../dataDisplay/Overlay";
import { ActionButton } from "../../trigger/ActionButton";

export type Props = {
  isOpen?: boolean;
  submitOnClick: MouseEventHandler<HTMLButtonElement>;
  cancelOnClick: (event?: MouseEvent<HTMLElement>) => void;
  heading: string;
  description?: string;
  submitLabel: string;
  cancelLabel?: string;
  maxWidth?: React.CSSProperties["maxWidth"];
};

const Dialog: React.FC<Props> = ({
  isOpen,
  submitOnClick,
  cancelOnClick,
  heading,
  description,
  submitLabel,
  cancelLabel,
  maxWidth,
}) => {
  const [isDisplayed, setDisplayed] = useState(false);
  const [isDisplayedOverlay, setDisplayedOverlay] = useState(false);

  const onCloseAfter = useCallback(() => {
    setDisplayedOverlay(false);
  }, []);

  const onAnimationEnd = useCallback(() => {
    if (isOpen) {
      setDisplayed(true);
      setDisplayedOverlay(true);
    } else {
      setDisplayed(false);
    }
  }, [isOpen]);

  return isOpen || isDisplayed || isDisplayedOverlay ? (
    <Overlay
      isOpen={isOpen}
      onCloseAfter={onCloseAfter}
      onClose={cancelOnClick}
    >
      <div
        css={containerStyle(isOpen, maxWidth)}
        onAnimationEnd={onAnimationEnd}
      >
        <p css={dialogHeadingStyle}>{heading}</p>
        <p css={dialogDescriptionStyle}>{description}</p>
        <div css={buttonGroupStyle}>
          {cancelLabel && (
            <ActionButton
              label={cancelLabel}
              kind="Secondary"
              onClick={cancelOnClick}
              css={buttonStyle}
            />
          )}
          <ActionButton
            label={submitLabel}
            kind="Primary"
            onClick={submitOnClick}
            css={buttonStyle}
          />
        </div>
      </div>
    </Overlay>
  ) : null;
};

const containerOpenAnimationKeyframes = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const containerCloseAnimationKeyframes = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const containerStyle = (
  isOpen?: Props["isOpen"],
  maxWidth?: React.CSSProperties["maxWidth"],
) => css`
  overflow-y: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  max-width: ${maxWidth ? maxWidth : "400px"};
  max-height: 100%;
  padding: ${Spacing[20]};
  background: ${Color.White};
  border-radius: ${RadiusSize[2]};
  transform: translate(-50%, -50%);
  animation: ease-in-out 0.3s forwards;
  animation-name: ${isOpen
    ? containerOpenAnimationKeyframes
    : containerCloseAnimationKeyframes};
`;

const dialogHeadingStyle = css`
  font-size: ${text.size[16]};
  font-weight: bold;
  line-height: ${LineHeight[140]};
`;

const dialogDescriptionStyle = css`
  font-size: ${text.size[12]};
  margin-top: ${Spacing[4]};
  line-height: ${LineHeight[160]};
`;

const buttonGroupStyle = css`
  display: flex;
  justify-content: right;
  gap: 0 ${Spacing[12]};
  margin-top: ${Spacing[16]};
`;

const buttonStyle = css`
  min-width: 120px;
`;

export default Dialog;
