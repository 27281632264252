import { ZodErrorMap } from "zod";

export const zodCustomErrorMap: ZodErrorMap = (issue, ctx) => {
  // メッセージが個別に設定されている場合はそれを返す
  if (issue.message) {
    return { message: issue.message };
  }

  switch (issue.code) {
    case "invalid_type": {
      // Required
      if (issue.received === "undefined" || issue.received === "null") {
        return { message: "入力してください" };
      }
      if (issue.expected === "integer") {
        return { message: "整数で入力してください" };
      }
      // Invalid type
      return { message: "不正な値です" };
    }

    case "invalid_enum_value":
      return { message: "選択してください" };

    case "invalid_string":
      return { message: "不正な文字が含まれています" };

    case "too_big": {
      switch (issue.type) {
        case "string":
          return {
            message: `${
              issue.inclusive
                ? issue.maximum
                : typeof issue.maximum === "bigint"
                ? issue.maximum - 1n
                : issue.maximum - 1
            }文字以下で入力してください`,
          };
        case "number":
          return {
            message: `${issue.maximum}${
              issue.inclusive ? "以下の" : "より小さい"
            }数字を入力してください`,
          };
        case "array":
          return { message: `${issue.maximum}件以上は追加できません` };
        default:
          return { message: ctx.defaultError };
      }
    }

    case "too_small": {
      switch (issue.type) {
        case "string":
          return {
            message: `${
              issue.inclusive
                ? issue.minimum
                : typeof issue.minimum === "bigint"
                ? issue.minimum + 1n
                : issue.minimum + 1
            }文字以上で入力してください`,
          };
        case "number":
          return {
            message: `${issue.minimum}${
              issue.inclusive ? "以上の" : "より大きい"
            }数字を入力してください`,
          };
        case "array":
          return { message: `最低${issue.minimum}件は追加してください` };
        default:
          return { message: ctx.defaultError };
      }
    }

    default:
      return { message: ctx.defaultError };
  }
};
