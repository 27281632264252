import { css, keyframes } from "@emotion/react";
import React, { MouseEvent, useCallback } from "react";
import { ZIndex } from "../../../../tokens";

type Props = {
  isOpen?: boolean;
  onClose?: (event?: MouseEvent<HTMLElement>) => void;
  onCloseAfter?: () => void;
  children: React.ReactNode;
} & JSX.IntrinsicElements["div"];

const Overlay: React.FC<Props> = ({
  isOpen = true,
  onClose,
  onCloseAfter,
  children,
  ...divProps
}) => {
  const onAnimationEnd = useCallback(() => {
    if (!isOpen) {
      onCloseAfter?.();
    }
  }, [isOpen, onCloseAfter]);

  return (
    <div css={overlayStyle} {...divProps}>
      <div
        css={overlayInnerStyle(isOpen)}
        onClick={onClose}
        onAnimationEnd={onAnimationEnd}
      />
      {children}
    </div>
  );
};

const overlayStyle = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: ${ZIndex.Modal};
`;

const overlayInnerStyle = (isOpen: Props["isOpen"]) => css`
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  z-index: -1;
  animation: 0.2s ease-in-out forwards
    ${isOpen ? overlayOpenAnimationKeyframes : overlayCloseAnimationKeyframes};
`;

const overlayOpenAnimationKeyframes = keyframes`
  0% {
    background-color: rgba(0, 0, 0 ,0);
  }
  100% {
    background-color: rgba(0, 0, 0 ,0.6);
  }
`;

const overlayCloseAnimationKeyframes = keyframes`
  0% {
    background-color: rgba(0, 0, 0 ,0.6);
  }
  100% {
    background-color: rgba(0, 0, 0 ,0);
  }
`;

export default Overlay;
