import * as React from "react";
import { createPortal } from "react-dom";

type PortalProps = {
  selector: string;
};

/**
 * Portal概要: https://reactjs.org/docs/portals.html
 * このコンポーネントはSSR実行時、
 * サーバサイドのレンダリング結果とクライアントで構築した仮想DOMの状態に差分が発生しないようにするためのものです。
 * もし差分が発生すると、clientで全体の再レンダリングが起こります。（React18からはErrorになるように）
 * useEffectがクライアントでのhydrate（仮想DOMとDOMが一致してることを確認してリスナーとかを付け直しSPAとして動く状態にする）
 * 後に実行されることを利用し、hydrate後にportal要素が展開されるようになっています。
 */
const ClientOnlyPortal: React.FC<React.PropsWithChildren<PortalProps>> = ({
  children,
  selector,
}) => {
  const ref = React.useRef<Element | null>();
  // ssr時および、hydrate時このmountedはfalse
  const [mounted, setMounted] = React.useState(false);

  // このeffectはclientでのhydrate後に実行される
  React.useEffect(() => {
    ref.current = document.querySelector(selector);
    setMounted(true);
  }, [selector]);

  // mountedはclientでこのコンポーネントがmountされて初めてtrueになるので、portalはclientでのみ展開される。
  return mounted ? createPortal(children, ref.current || document.body) : null;
};

export default ClientOnlyPortal;
