import {
  FC,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";

const defaultValues = {
  currentId: undefined as symbol | undefined,
  openModal: (_id: symbol): void => {
    throw new Error("Please use ModalProvider to properly access.");
  },
  closeModal: (): void => {
    throw new Error("Please use ModalProvider to properly access.");
  },
  closeAfter: (): void => {
    throw new Error("Please use ModalProvider to properly access.");
  },
  setIsActive: (_bool: boolean): void => {
    throw new Error("Please use ModalProvider to properly access.");
  },
  isActive: false,
};

const ModalContext = createContext(defaultValues);

type Props = {
  children: ReactNode;
};

export const useModalContext = () => {
  return useContext(ModalContext);
};

export const ModalProvider: FC<Props> = ({ children }) => {
  const [currentId, setCurrentId] = useState<symbol>();
  const [nextId, setNextId] = useState<symbol>();
  const [isActive, setIsActive] = useState(false);

  const closeModal = useCallback(() => {
    setCurrentId(undefined);
  }, []);

  const openModal = useCallback(
    (id: symbol) => {
      if (currentId) {
        setNextId(id);
        closeModal();

        return;
      }

      setIsActive(true);
      setCurrentId(id);
    },
    [closeModal, currentId],
  );

  const closeAfter = useCallback(() => {
    setIsActive(false);

    if (nextId) {
      setNextId(undefined);
      openModal(nextId);
    }
  }, [nextId, openModal]);

  return (
    <ModalContext.Provider
      value={{
        ...defaultValues,
        currentId,
        isActive,
        openModal,
        closeModal,
        setIsActive,
        closeAfter,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
