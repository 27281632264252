import { css } from "@emotion/react";
import { text } from "@estie-inc/design-tokens";
import React from "react";
import { Color, Spacing, Shadow } from "../../../../tokens";

export type Props = {
  isOpen?: boolean;
  children: React.ReactNode;
  type: keyof typeof BackgroundType;
};

export const BackgroundType = {
  Success: Color.Attention.Success.Base,
  Caution: Color.Attention.Caution.Base,
  Info: Color.Primary.Base,
};

const Toast: React.FC<Props> = ({ isOpen, type, children }) => {
  return (
    <div css={wrapperStyle(BackgroundType[type], isOpen)}>
      <p css={textStyle}>{children}</p>
    </div>
  );
};

const wrapperStyle = (type: string, isOpen?: boolean) => css`
  display: ${isOpen ? "block" : "none"};
  width: 100%;
  padding: ${Spacing[16]};
  margin-bottom: ${Spacing[8]};
  background: ${type};
  box-shadow: ${Shadow.Box[3]};
`;

const textStyle = css`
  font-size: ${text.size[14]};
  font-weight: bold;
  color: ${Color.White};
  text-align: center;
`;

export default Toast;
