import { css, SerializedStyles } from "@emotion/react";
import { text } from "@estie-inc/design-tokens";
import { IconProp as FaIcon } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Link from "next/link";
import React, { MouseEvent, useCallback, useState } from "react";
import { inlineFlexCenter } from "../../../../styles/mixin";
import { Color, Spacing, RadiusSize, ZIndex } from "../../../../tokens";
import MenuList, { MenuItem } from "../../navigation/MenuList";

type ActionButtonProps = {
  kind: keyof typeof Kind;
  label: string;
  isDisabled?: boolean;
  icon?: FaIcon;
  testId?: string;
} & JSX.IntrinsicElements["button"];

type PullDownButtonProps = {
  kind: keyof typeof Kind;
  label: string;
  menuList: {
    menuItems: MenuItem[];
    width?: string;
    className?: string;
  };
  isDisabled?: boolean;
  icon?: FaIcon;
  testId?: string;
} & JSX.IntrinsicElements["button"];

type LinkedButtonProps = {
  kind: keyof typeof Kind;
  label: string;
  href: string;
  icon?: FaIcon;
  testId?: string;
} & JSX.IntrinsicElements["a"];

const colorStyle = (
  bgColor: React.CSSProperties["color"],
  txtColor: React.CSSProperties["color"],
) => css`
  background: ${bgColor};
  color: ${txtColor};
  &:hover {
    filter: brightness(88%);
  }
`;

const Kind = {
  Primary: css`
    ${colorStyle(Color.Primary.Base, Color.White)}
    border: 1px solid ${Color.Primary.Base};
  `,
  Secondary: css`
    background: ${Color.White};
    border: 1px solid ${Color.Neutral.Light.Primary};
    color: ${Color.Primary.Base};
    box-sizing: border-box;
    &:hover {
      .a-actionbutton__text {
        text-decoration: underline;
      }
    }
  `,
  Caution: css`
    background: ${Color.White};
    border: 1px solid ${Color.Neutral.Light.Primary};
    color: ${Color.Attention.Caution.Base};
    box-sizing: border-box;
    &:hover {
      .a-actionbutton__text {
        text-decoration: underline;
      }
    }
  `,
  ExcelDownload: css`
    ${colorStyle(Color.Brand.Excel, Color.White)}
  `,
  Black: css`
    ${colorStyle(Color.Neutral.Base.Primary, Color.White)};
    .a-actionbutton__text {
      font-size: ${text.size[16]};
      font-weight: normal;
    }
  `,
};
const ActionButton: React.FC<ActionButtonProps> = ({
  kind,
  icon,
  label,
  isDisabled,
  onClick,
  className,
  testId,
  ...buttonProps
}) => {
  const handleClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      if (!onClick || isDisabled) return;
      onClick(e);
    },
    [onClick, isDisabled],
  );
  return (
    <button
      {...buttonProps}
      css={wrapperStyle(Kind[kind], isDisabled)}
      onClick={handleClick}
      disabled={isDisabled}
      className={className}
      data-testid={testId}
    >
      <Contents icon={icon} label={label} />
    </button>
  );
};

const MenuListStyle = css`
  position: absolute;
  text-align: left;
  top: calc(100%);
  margin-top: 4px;
  right: -1px;
  width: auto;
  min-width: 100%;
  white-space: nowrap;
  box-sizing: content-box;
  font-weight: normal;
  z-index: ${ZIndex.PullDown};
`;

const PullDownButton: React.FC<PullDownButtonProps> = ({
  kind,
  icon,
  label,
  menuList,
  isDisabled,
  className,
  testId,
  ...buttonProps
}) => {
  const [openMenu, toggleMenu] = useState(false);
  return (
    <button
      {...buttonProps}
      css={wrapperStyle(Kind[kind], isDisabled)}
      onClick={() => toggleMenu(!openMenu)}
      onBlur={() => toggleMenu(false)}
      disabled={isDisabled}
      className={className}
      data-testid={testId}
    >
      <Contents icon={icon} label={label} />
      {openMenu && (
        <MenuList
          menuItems={menuList.menuItems}
          width={menuList.width}
          className={menuList.className}
          css={MenuListStyle}
        />
      )}
    </button>
  );
};

const LinkedButton: React.FC<LinkedButtonProps> = ({
  kind,
  icon,
  label,
  href,
  className,
  testId,
  ...anchorProps
}) => {
  return (
    <Link href={href} passHref>
      <a
        {...anchorProps}
        css={wrapperStyle(Kind[kind], false)}
        className={className}
        data-testid={testId}
      >
        <Contents icon={icon} label={label} />
      </a>
    </Link>
  );
};

const wrapperStyle = (
  styledByType: SerializedStyles,
  isDisabled?: boolean,
) => css`
  position: relative;
  ${inlineFlexCenter}
  padding: ${Spacing[8]};
  border-radius: ${RadiusSize[2]};
  font-size: ${text.size[12]};
  line-height: 14px;
  font-weight: bold;
  transition: all 0.3s ease-out;
  ${styledByType}
  ${isDisabled &&
  css`
    background: ${Color.Neutral.Light.Secondary};
    color: ${Color.Neutral.Base.Secondary};
    border: 1px solid ${Color.Neutral.Light.Secondary};
    cursor: default;
    pointer-events: none;
  `}
`;

const iconWrapperStyle = css`
  ${inlineFlexCenter}
  width: 14px;
  height: 14px;
  margin-right: ${Spacing[4]};
`;

type ContentsProps = {
  label: string;
  icon?: FaIcon;
};

const Contents: React.FC<ContentsProps> = ({ icon, label }) => {
  return (
    <>
      {icon && (
        <div css={iconWrapperStyle}>
          <FontAwesomeIcon icon={icon} />
        </div>
      )}
      <div className="a-actionbutton__text">{label}</div>
    </>
  );
};

export { ActionButton, LinkedButton, PullDownButton };
