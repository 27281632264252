import { createClient, cacheExchange, fetchExchange } from "@urql/core";
import { AnyVariables, mapExchange } from "urql";

export * from "./__generated__";
export * from "./__generated__/graphql";
export * from "./__generated__/gql";

const dropTypename = (obj: unknown): unknown => {
  if (Array.isArray(obj)) {
    return obj.map((item: unknown) => dropTypename(item));
  } else if (obj !== null && typeof obj === "object") {
    return Object.entries(obj).reduce(
      (acc: Record<string, unknown>, [key, value]) => {
        if (key !== "__typename") acc[key] = dropTypename(value);
        return acc;
      },
      {},
    );
  } else {
    return obj;
  }
};

export const urqlClient = createClient({
  url: "/api/proxy/graphql",
  exchanges: [
    cacheExchange,
    // GraphQL に送るとき取ってきた __typename も送るとエラーになるので、
    // 使いやすさのために __typename を削除する
    mapExchange({
      onOperation(operation) {
        if (operation.kind === "mutation") {
          const variables = operation.variables
            ? (dropTypename(operation.variables) as AnyVariables)
            : undefined;
          return { ...operation, variables };
        } else {
          return operation;
        }
      },
    }),
    fetchExchange,
  ],
});

export const urqlAdminClient = createClient({
  url: "/api/proxy/graphql_admin",
  exchanges: [
    cacheExchange,
    // GraphQL に送るとき取ってきた __typename も送るとエラーになるので、
    // 使いやすさのために __typename を削除する
    mapExchange({
      onOperation(operation) {
        if (operation.kind === "mutation") {
          const variables = operation.variables
            ? (dropTypename(operation.variables) as AnyVariables)
            : undefined;
          return { ...operation, variables };
        } else {
          return operation;
        }
      },
    }),
    fetchExchange,
  ],
});
