import { css } from "@emotion/react";
import { spacing, radius, text, color } from "@estie-inc/design-tokens";
import React from "react";

type Props = {
  children: React.ReactNode;
  className?: string;
};

const Tooltip: React.FC<Props> = ({ children, className }) => {
  return (
    <span css={tooltipStyle} className={className}>
      {children}
    </span>
  );
};

const tooltipStyle = css`
  display: inline-block;
  font-size: ${text.size[12]};
  color: ${color.basis.white};
  background: ${color.basis.black};
  padding: ${spacing[8]};
  border-radius: ${radius[2]};
  line-height: ${text.lineHeight[120]};
`;

export default Tooltip;
