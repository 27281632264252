import { css } from "@emotion/react";
import React from "react";
import { Color } from "../tokens";

export const sticky = (
  top?: React.CSSProperties["top"],
  right?: React.CSSProperties["right"],
  bottom?: React.CSSProperties["bottom"],
  left?: React.CSSProperties["left"],
) => css`
  position: sticky;
  top: ${top ? top : "auto"};
  right: ${right ? right : "auto"};
  bottom: ${bottom ? bottom : "auto"};
  left: ${left ? left : "auto"};
`;

export const grid = (
  columns: React.CSSProperties["gridTemplateColumns"],
  columnGap: React.CSSProperties["columnGap"],
  rows: React.CSSProperties["gridTemplateRows"],
  rowGap: React.CSSProperties["rowGap"],
) => css`
  display: grid;
  grid-template-columns: ${columns};
  column-gap: ${columnGap};
  grid-template-rows: ${rows};
  row-gap: ${rowGap};
  word-break: break-all;
`;

export const flexCenter = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const flexAlignItemsCenter = css`
  display: flex;
  align-items: center;
`;

export const inlineFlexCenter = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

export const inlineFlexAlignItemsCenter = css`
  display: inline-flex;
  align-items: center;
`;

export const backgroundImage = (
  size: React.CSSProperties["backgroundSize"],
  imgUrl?: string,
) => css`
  background-position: center;
  background-repeat: no-repeat;
  background-size: ${size};
  background-color: ${Color.White};
  background-image: ${imgUrl ? `url(${imgUrl})` : "none"};
`;

export const textCut = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const lineClamp = (clampNum: number) => css`
  display: -webkit-box;
  -webkit-line-clamp: ${clampNum};
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const placeholderColor = (color: React.CSSProperties["color"]) => css`
  &::placeholder {
    color: ${color};
  }
  // IE
  &:-ms-input-placeholder {
    color: ${color};
  }
  // Edge
  &::-ms-input-placeholder {
    color: ${color};
  }
`;

export const transition = (second: number) => css`
  transition: all ${second}s ease-in;
`;

export const hideScrollBar = css`
  -ms-overflow-style: none; /* IE, Edge 対応 */
  scrollbar-width: none; /* Firefox 対応 */
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari 対応 */
  }
`;

export const thinScrollBar = css`
  /* Firefox 対応 */
  scrollbar-width: 4px;

  /* Chrome, Safari 対応 */
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(155, 155, 155, 0.5);
    border-radius: 2px;
    border: transparent;
  }
`;

export const noPrint = css`
  @media print {
    display: none;
  }
`;
