import { css } from "@emotion/react";
import { text } from "@estie-inc/design-tokens";
import React, { ReactNode, useContext, useState } from "react";
import { ZIndex } from "../../tokens";

const LoadingContext = React.createContext({
  setIsLoading(_isLoading: boolean): void {
    throw new Error("loading context: setIsLoading must be set");
  },
});

export const useLoading = () => useContext(LoadingContext);

const LoadingOverlay = () => (
  <div
    id="loading"
    css={css`
      background-color: black;
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: ${ZIndex.Feedback};
      opacity: 0.6;
      color: white;
      display: flex;
      justify-content: center;
    `}
  >
    <div
      css={css`
        display: flex;
        flex-direction: column;
        font-size: ${text.size[16]};
        justify-content: center;
        align-items: center;
      `}
    >
      <img
        alt="処理中画像"
        src={"/loading.gif"}
        css={css`
          width: 100px;
        `}
      />
      処理中
    </div>
  </div>
);

export const LoadingProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <LoadingContext.Provider value={{ setIsLoading }}>
      {children}
      {isLoading && <LoadingOverlay />}
    </LoadingContext.Provider>
  );
};
